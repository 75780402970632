@import '~antd/dist/antd.css';

@font-face {
  font-family: AvenirLTStdBook;
  src: url(../fonts/AvenirLTStd-Book.otf);
}

@font-face {
  font-family: AvenirLTStdBookOblique;
  src: url(../fonts/AvenirLTStd-BookOblique.otf);
}

@font-face {
  font-family: AvenirLTStdHeavy;
  src: url(../fonts/AvenirLTStd-Heavy.otf);
}

@font-face {
  font-family: AvenirLTStdHeavyOblique;
  src: url(../fonts/AvenirLTStd-HeavyOblique.otf);
}

@font-face {
  font-family: GothamRoundedBold;
  src: url(../fonts/Gotham-Rounded-Bold.ttf);
}

#root {
  background-color: #f5f5f5;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 100% 100%;
  background-size: 100% 100%;
  min-height: 100vh;
}

p {
  font-family: AvenirLTStdBook;
  color: #000000;
  font-size: 16px;
  margin-bottom: 5px;
  word-break: break-word;
}
h3,
h4,
h5,
h6 {
  font-family: AvenirLTStdHeavy;
  margin-bottom: 8px;
}
h3 {
  color: #050593;
  font-size: 24px;
}
h4 {
  color: #000000;
  font-size: 20px;
}
h5 {
  color: #999999;
  font-size: 16px;
}
h6 {
  color: #000000;
  font-size: 16px;
}

/* 
 Do not add component specific styles here
 This place is only for global generic HTML elements
 For utility classes , use theme-ui `sx` prop
*/
